









































































import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import {
  mansekiStatus,
  isWaitingList,
  isCourseApplicationMethod,
  courseTimeStatus,
  isReservedStatus,
  isReservedStatus2,
  isReservedStatus3,
  isCancelNoteStatus,
  showCancelFee,
  reserveStatusText,
} from '../../../plugins/helper';
import { WPPost, Course } from '../../../../types/typescript-axios/api';

@Component
export default class CourseHistoryBox extends Vue {
  @Prop({ default: null })
  private course!: any;

  get isWaitngListStatus(): boolean {
    // 申込み受付期間前：0
    // 申込み受付期間中：1
    // 申込み期間終了：2
    // 開催終了：3
    return isWaitingList(this.course);
  }

  get isManseki(): boolean {
    // 申込み受付期間前：0
    // 申込み受付期間中：1
    // 申込み期間終了：2
    // 開催終了：3
    return mansekiStatus(this.course);
  }

  get isApplicationMethod(): number {
    // 申込み受付期間前：0
    // 申込み受付期間中：1
    // 申込み期間終了：2
    // 開催終了：3
    return isCourseApplicationMethod(this.course);
  }

  get timeStatus(): number {
    // 申込み受付期間前：0
    // 申込み受付期間中：1
    // 申込み期間終了：2
    // 開催終了：3
    return courseTimeStatus(this.course);
  }

  private get isStatus1(): number {
    // -1: 未申し込み
    // 0: 申込受付
    // 1: キャンセル
    // 2: キャンセル待ち
    return isReservedStatus(this.course);
  }

  private get isStatus2() {
    // 講座の予約ステータス2
    // -1: 未申し込み
    // 0: -
    // 1: キャンセル待ち繰り上げ
    // 2: 繰り上げ確認中
    // 3: キャンセル料発生
    // 4: 無断キャンセル
    // 5: キャンセル待ち辞退
    return isReservedStatus2(this.course);
  }

  private get isStatus3() {
    // 講座の支払い状況
    // -1: 未申し込み
    // 0: -
    // 1: 未入金
    // 2: 入金済
    return isReservedStatus3(this.course);
  }

  get statusText(): string {
    return reserveStatusText(this.course);
  }

  private get isNoteStatus(): number {
    return isCancelNoteStatus(this.course);
  }

  @Emit()
  private brReplace(item: string) {
    let result = '';
    result = item.replace( /\n|\r\n|\r/g, '<br>' );
    return result;
  }

  @Emit()
  private formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
}
